exports.components = {
  "component---src-landing-pages-documents-landing-tsx": () => import("./../../../src/landingPages/documents-landing.tsx" /* webpackChunkName: "component---src-landing-pages-documents-landing-tsx" */),
  "component---src-landing-pages-index-tsx": () => import("./../../../src/landingPages/index.tsx" /* webpackChunkName: "component---src-landing-pages-index-tsx" */),
  "component---src-landing-pages-news-landing-tsx": () => import("./../../../src/landingPages/news-landing.tsx" /* webpackChunkName: "component---src-landing-pages-news-landing-tsx" */),
  "component---src-landing-pages-themes-landing-tsx": () => import("./../../../src/landingPages/themes-landing.tsx" /* webpackChunkName: "component---src-landing-pages-themes-landing-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-document-archive-page-tsx": () => import("./../../../src/templates/document-archive-page.tsx" /* webpackChunkName: "component---src-templates-document-archive-page-tsx" */),
  "component---src-templates-document-tsx": () => import("./../../../src/templates/document.tsx" /* webpackChunkName: "component---src-templates-document-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/newsItem.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-theme-page-tsx": () => import("./../../../src/templates/theme-page.tsx" /* webpackChunkName: "component---src-templates-theme-page-tsx" */)
}

